<template>
  <div>
    <v-row class="mt-5 mb-5 pb-5 mx-12 document-type-select__border--bottom" no-gutters>
      <v-col cols="12">
        <v-row align="center" justify="start">
          <v-col cols="auto" class="pr-3" :class="{ 'pt-0': $vuetify.breakpoint.mobile }">
            <h1 class="title font-weight-bold text-left mb-0">
              {{ $t('aml.document_type.select') }}
            </h1>
          </v-col>

          <v-col :class="{ 'pt-0': $vuetify.breakpoint.mobile }">
            <v-select
              v-model="selectedDocumentTypeId"
              :items="availableChoices"
              :item-text="(e) => $t(e.titleKey)"
              item-value="documentTypeId"
              hide-details
              outlined
              dense
              @change="$emit('user-change-select')"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>

      <template>
        <v-col
          v-for="(documentTypeImage, index) in currentSelectedDocumentTypeImages"
          :key="index"
          cols="12"
        >
          <UploadDocumentImage
            :document-type-id="selectedDocumentTypeId"
            :image-id="getDocumentImageId(documentTypeImage)"
            :side="documentTypeImage.side"
          />
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UploadDocumentImage from '@verification/components/UploadDocumentImage';

export default {
  name: 'DocumentTypesSelect',
  components: {
    UploadDocumentImage,
  },
  props: {
    value: [Number, String],
    availableChoices: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentDocumentImagesType: 'authsDocument/currentDocumentImagesType',
      selectedDocumentTypeImages: 'authsDocument/selectedDocumentTypeImages',
    }),
    selectedDocumentTypeId: {
      get() {
        return this.value;
      },
      set(documentTypeId) {
        this.$emit('input', documentTypeId);
      },
    },
    currentSelectedDocumentTypeImages() {
      return this.selectedDocumentTypeImages(this.selectedDocumentTypeId);
    },
  },
  methods: {
    getDocumentImageId(documentTypeImage) {
      return documentTypeImage?.image?.id || null;
    },
  },
};
</script>

<style lang="scss" scoped>
.document-type-select {
  &__border--bottom {
    border-bottom: 1px solid #dee7f4;
  }
}
</style>
