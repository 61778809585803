<template>
  <Loader v-if="isLoading" />

  <v-card
    v-else
    :color="workspaceLayerColor"
    class="mx-auto my-md-10 pt-10 text-center"
    width="100%"
    max-width="45rem"
  >
    <AuthsProgressBar :step="2" />

    <v-card-text class="px-10 px-md-15">
      <h1 class="headline font-weight-black secondary--text mb-2">
        {{ $t('aml.identity2') }}
      </h1>
    </v-card-text>

    <SendToPhoneBox
      v-if="!$vuetify.breakpoint.mobile"
      v-model="loadChanges"
      :infoText="`${$t('aml.info.mobile_upload')}`"
      :sent-text="`${$t('aml.insert.mobile_phone')}`"
      class="pb-1"
      @user-change-select="selectChangedByUser = false"
    />

    <DocumentTypesSelect
      v-model="firstSelectedDocumentTypeId"
      :available-choices="firstSelectAvailableChoices"
      :key="firstSelectedDocumentTypeId"
      @user-change-select="selectChangedByUser = true"
    />

    <DocumentTypesSelect
      v-if="!hasOnlyOneDocumentFeature"
      v-model="secondSelectedDocumentTypeId"
      :available-choices="secondSelectAvailableChoices"
      :key="secondSelectedDocumentTypeId"
      @user-change-select="selectChangedByUser = true"
    />

    <DocumentTypeSelfie :selfie="selfieItems[0]" />

    <v-row class="mx-5 mx-md-12 py-5 identity__border--bottom" align="center" justify="center">
      <v-col cols="12" class="py-0">
        <v-checkbox
          v-model="approveWithTerms"
          class="mt-0"
          hide-details
          :label="$t('aml.auth.approve_with_save_data')"
        >
          <template v-slot:label>
            <p class="mb-0 subscription-container__description">
              {{ $t('aml.auth.approve_with_save_data') }}
            </p>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>

    <v-card-actions class="px-10 mt-5 mb-15">
      <v-btn
        class="mt-5 mx-auto ml-md-auto mr-md-0"
        color="primary"
        min-width="15rem"
        :loading="isSubmitted"
        :disabled="!canContinue"
        x-large
        @click="submit()"
      >
        {{ $t('general.continue') }}
      </v-btn>
    </v-card-actions>

    <AlertMessage v-model="error" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AuthsProgressBar from '@verification/components/AuthsProgressBar';
import SendToPhoneBox from '@verification/components/SendToPhoneBox';
import Loader from '@/components/Loader';
import AlertMessage from '@verification/components/AlertMessage';
import DocumentTypesSelect from '@verification/components/DocumentTypesSelect';
import DocumentTypeSelfie from '@verification/components/DocumentTypeSelfie';
import { authDocumentSeen } from '@verification/services/logService';

export default {
  name: 'Identity',
  components: {
    Loader,
    SendToPhoneBox,
    AlertMessage,
    AuthsProgressBar,
    DocumentTypesSelect,
    DocumentTypeSelfie,
  },
  data() {
    return {
      error: false,
      loaded: false,
      submitted: false,
      approveWithTerms: false,
      mobilePhoneNumber: '+420',
      loadChanges: false,
      firstSelectedDocumentTypeId: 5,
      secondSelectedDocumentTypeId: 4,
      selectChangedByUser: false,
    };
  },
  computed: {
    ...mapGetters({
      availableDocumentChoices: 'authsDocument/availableDocumentChoices',
      authsDocument: 'authsDocument/document',
      currentDocumentImagesType: 'authsDocument/currentDocumentImagesType',
      selectedDocumentIds: 'authsDocument/selectedDocumentIds',
      selfieItems: 'authsDocument/selfieItems',
      workspaceLayerColor: 'auths/workspaceLayerColor',
      hasOnlyOneDocumentFeature: 'authsDocument/hasOnlyOneDocumentFeature',
    }),
    firstSelectAvailableChoices() {
      if (this.hasOnlyOneDocumentFeature) {
        return this.availableDocumentChoices;
      }

      return this.availableDocumentChoices.filter(
        (documentType) => documentType.documentTypeId !== this.secondSelectedDocumentTypeId,
      );
    },
    secondSelectAvailableChoices() {
      return this.availableDocumentChoices.filter(
        (documentType) => documentType.documentTypeId !== this.firstSelectedDocumentTypeId,
      );
    },
    isLoading() {
      return !this.loaded;
    },
    isSubmitted() {
      return this.submitted;
    },
    canContinue() {
      return this.approveWithTerms && this.isAllDocumentImagesFilled;
    },
    isAllDocumentImagesFilled() {
      let isFilled = true;

      if (this.hasOnlyOneDocumentFeature) {
        for (let item of this.currentDocumentImagesType(this.firstSelectedDocumentTypeId)) {
          if (null === item?.image) {
            return false;
          }
        }

        return true;
      }

      this.selectedDocumentIds.forEach((e) => {
        this.currentDocumentImagesType(e).forEach((ev) => {
          if (null === ev.image) {
            isFilled = false;
          }
        });
      });

      return isFilled;
    },
  },
  mounted() {
    authDocumentSeen({ authToken: this.$route.params.authToken });
    this.fetchAuths(this.$route.params.authToken).then(() => {
      this.firstSelectedDocumentTypeId = this.selectedDocumentIds[0];
      this.secondSelectedDocumentTypeId = this.selectedDocumentIds[1];
      this.loaded = true;
    });
  },
  methods: {
    ...mapActions({
      createDocumentImage: 'authsDocument/createDocumentImage',
      fetchAuths: 'auths/fetchAuths',
      setSelectedDocumentType: 'authsDocument/setSelectedDocumentType',
      setModeEditing: 'auths/setModeEditing',
      submitDocument: 'authsDocument/submitDocument',
    }),
    async submit() {
      const authToken = this.$route.params.authToken;

      this.submitted = true;
      this.setModeEditing(false);
      this.submitDocument({ authToken }).then(
        () => {
          return this.$router.push({
            name: 'authentication-micropayment',
            params: { authToken },
          });
        },
        () => {
          this.submitted = false;
          this.error = true;
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.identity {
  &__border--bottom {
    border-top: 1px solid #dee7f4;
    border-bottom: 1px solid #dee7f4;
  }
}

.subscription-container {
  max-width: 1050px;

  &__description {
    color: #000000;
    font-weight: normal;

    a {
      color: #8356b5 !important;
    }
  }
}
</style>
