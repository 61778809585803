<template>
  <v-row class="mt-5 mb-5 pb-5 mx-12" no-gutters>
    <v-col cols="12">
      <h1 class="title font-weight-bold text-left mb-0">
        {{ $t(selfie.titleKey) }}
      </h1>
    </v-col>

    <v-col cols="12">
      <UploadDocumentImage
        :document-type-id="selfie.documentTypeId"
        :image-id="imageId"
        :side="selfie.side"
        :is-disabled="isDisabled"
      />
    </v-col>
  </v-row>
</template>

<script>
import UploadDocumentImage from '@verification/components/UploadDocumentImage';

export default {
  name: 'DocumentTypeSelfie',
  components: {
    UploadDocumentImage,
  },
  props: {
    selfie: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageId() {
      return this.selfie.image?.id || null;
    },
    isDisabled() {
      return this.imageId !== null;
    },
  },
};
</script>

<style lang="scss" scoped>
.document-type-select {
  &__border--bottom {
    border-bottom: 1px solid #dee7f4;
  }
}
</style>
