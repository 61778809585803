<template>
  <v-row align="center" justify="start" class="my-1">
    <v-col cols="12" :class="{ 'px-0': $vuetify.breakpoint.mobile }">
      <v-expand-transition>
        <v-row align="center" justify="center" justify-md="start" class="pa-0 ma-0">
          <v-col cols="auto" class="col-auto pa-0" :class="{ 'pr-4': $vuetify.breakpoint.mobile }">
            <v-avatar
              :size="$vuetify.breakpoint.mobile ? 60 : 100"
              rounded
              style="background-color: #e8edf5"
            >
              <v-img
                v-if="uploadedImage !== null"
                :src="uploadedImage.file_url"
                width="100%"
                height="100%"
                style="object-fit: cover; border-radius: 8px; overflow: hidden"
              ></v-img>

              <v-img
                v-else-if="imageData !== null && uploadedImage === null"
                :src="imageData"
                width=" 100%"
                height="100%"
                style="object-fit: cover; border-radius: 8px; overflow: hidden"
              ></v-img>

              <v-icon v-else color="#B4C1D6" large>
                {{ `${uploadedImageObject.icon}` }}
              </v-icon>
            </v-avatar>
          </v-col>

          <v-col class="col pa-0 pa-md pl-md-10 text-left">
            <div :class="{ 'body-2': $vuetify.breakpoint.mobile }">
              {{ $t(uploadedImageObject.descriptionKey) }}
            </div>

            <v-row align="center" justify="space-between">
              <v-col>
                <v-row v-if="uploadedImage === null" align="center" justify="space-between">
                  <v-col>
                    <v-file-input
                      ref="fileInput"
                      accept="image/*"
                      v-model="image"
                      :show-size="1000"
                      :loading="uploading"
                      :rules="rules"
                      :disabled="isDisabled"
                      :error="this.uploadError !== null"
                      :messages="this.uploadError !== null ? this.$t('upload.failed') : ''"
                      @click:clear="imageData = null"
                      @change="uploadPicture"
                    />
                  </v-col>

                  <v-col class="col-auto">
                    <WebCamera
                      :photo-type="photoType"
                      :default-facing-mode="facingMode"
                      @upload-image="upload"
                    >
                      <template v-slot:activator>
                        <v-btn
                          color="primary"
                          :small="$vuetify.breakpoint.mobile"
                          :disabled="isDisabled"
                          :loading="uploading"
                          outlined
                        >
                          {{ $t('general.photo_shooting') }}
                        </v-btn>
                      </template>
                    </WebCamera>
                  </v-col>
                </v-row>

                <StatePreview
                  v-else
                  type="success"
                  :class="{ 'mt-3': $vuetify.breakpoint.mobile }"
                  :label="$t('general.uploaded')"
                />
              </v-col>

              <v-col v-if="uploadedImage !== null" cols="auto">
                <v-btn
                  type="submit"
                  color="error"
                  :small="$vuetify.breakpoint.mobile"
                  :disabled="isDisabled"
                  :loading="uploading"
                  outlined
                  @click="deleteImage"
                >
                  {{ $t('general.delete') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StatePreview from '@verification/components/StatePreview';
import WebCamera from '@/packages/web-camera/WebCamera';

export default {
  name: 'UploadDocumentImage',
  components: {
    StatePreview,
    WebCamera,
  },
  props: {
    documentTypeId: {
      type: [Number, String],
      required: true,
    },
    imageId: {
      type: [Number, String],
      default: null,
    },
    side: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    image: null,
    imageData: null,
    uploading: false,
    uploadError: null,
    uploadMinorDocument: false,
    rules: [(value) => !value || value.size < 50000000 || 'Image size should be less than 50 MB!'],
  }),
  computed: {
    ...mapGetters({
      currentDocumentImage: 'authsDocument/currentDocumentImage',
    }),
    facingMode() {
      return 2 === this.documentTypeId ? 'user' : 'environment';
    },
    photoType() {
      return 2 === this.documentTypeId ? 'photo' : 'document';
    },
    uploadedImageObject() {
      return this.currentDocumentImage(this.documentTypeId, this.side);
    },
    uploadedImage() {
      return this.uploadedImageObject?.image || null;
    },
  },
  methods: {
    ...mapActions({
      createDocumentImage: 'authsDocument/createDocumentImage',
      deleteDocumentImage: 'authsDocument/deleteDocumentImage',
      fetchDocument: 'authsDocument/fetchDocument',
    }),
    async uploadPicture() {
      if (null === this.image) {
        return;
      }

      await this.upload(this.image, this.image.name);
    },
    async upload(image, title = 'documentPicture') {
      this.uploading = true;
      this.uploadError = null;

      const formData = new FormData();
      formData.append('auth_image', image, title);
      formData.append('auth_document_type_id', `${this.documentTypeId}`);
      formData.append('auth_document_side', this.side);

      return this.createDocumentImage({
        authToken: this.$route.params.authToken,
        data: formData,
      }).then(
        () => {
          this.fetchDocument(this.$route.params.authToken).then(() => {
            this.uploadError = null;
            this.uploading = false;
          });
        },
        () => {
          this.uploadError = this.$t('upload.failed');
          this.uploading = false;
        },
      );
    },
    deleteImage() {
      this.uploading = true;
      this.deleteDocumentImage({
        authToken: this.$route.params.authToken,
        imageId: this.imageId,
      }).then(() => {
        this.fetchDocument(this.$route.params.authToken).then(() => {
          this.uploading = false;
          this.image = null;
          this.imageData = null;
          this.uploadError = null;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
